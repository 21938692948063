* {
  box-sizing: border-box;
}
.seat {
  color: #f46a6a !important ;
  background-color: #f8e6e6 !important;
  transition: all 0.5s ease;
  border-color: transparent;
  border: none !important;
}

.seat.selected {
  color: #fff !important;
  background-color: rgb(40, 190, 40) !important;
}

.seat.sold {
  opacity: 0.7;
  color: #f8e6e6 !important;
  cursor: no-drop !important;
  background-color: rgb(202, 196, 196) !important;
}
.seat:not(.sold):not(.selected):hover {
  color: #fff !important;
  cursor: pointer;
  transform: scale(1);
  background-color: #f46a6a !important;
}
